@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes moveBee {
    0% { top: 10%; left: 10%; }
    25% { top: 20%; left: 80%; }
    50% { top: 50%; left: 50%; }
    75% { top: 80%; left: 20%; }
    100% { top: 10%; left: 10%; }
  }
  
  @keyframes moveButterfly {
    0% { top: 10%; left: 80%; }
    25% { top: 30%; left: 60%; }
    50% { top: 60%; left: 80%; }
    75% { top: 80%; left: 40%; }
    100% { top: 10%; left: 80%; }
  }
  
  @keyframes moveButterfly2 {
    0% { top: 80%; left: 10%; }
    25% { top: 70%; left: 30%; }
    50% { top: 60%; left: 50%; }
    75% { top: 40%; left: 80%; }
    100% { top: 80%; left: 10%; }
  }
  
  @keyframes moveBee2 {
    0% { top: 80%; left: 80%; }
    25% { top: 60%; left: 60%; }
    50% { top: 50%; left: 50%; }
    75% { top: 20%; left: 20%; }
    100% { top: 80%; left: 80%; }
  }
  
  .bee {
    animation: moveBee 5s linear infinite;
  }
  
  .bee2 {
    animation: moveBee2 5s linear infinite;
  }
  
  .butterfly {
    animation: moveButterfly 5s linear infinite;
  }
  
  .butterfly2 {
    animation: moveButterfly2 5s linear infinite;
  }
  
 /* styles.css */
.thread {
    position: absolute;
    width: 2px;
    height: 80vh;
    background-color: red;
    animation: sway 2s infinite alternate ease-in-out;
  }
  
  .thread-connect {
    position: absolute;
    width: 2px;
    height: 50px;
    background-color: red;
    animation: sway 2s infinite alternate ease-in-out;
  }
  
  .top-left {
    top: 0;
    left: 0;
    transform: rotate(-45deg);
  }
  
  .top-right {
    top: 0;
    right: 0;
    transform: rotate(45deg);
  }
  
  .bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
  }
  
  .bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(-45deg);
  }
  
  @keyframes sway {
    0% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(5deg);
    }
  }
  section {
    padding: 50px 0;
  }
  
  
